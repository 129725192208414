import { useAxios } from '@/composables/useAxios'

import { PromiseType } from '@/utils/types/PromiseType'
import { UseGetDashboard } from './types/dashboard'

const VERSION = 'v1'
const RESOURCE = 'dashboard'

function useGetDashboard(): UseGetDashboard {
  const axios = useAxios<PromiseType<ReturnType<UseGetDashboard['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
  })
  return axios
}

export { useGetDashboard }
