
import { defineComponent, reactive } from '@vue/composition-api'
import { mdiArrowRight } from '@mdi/js'

import { useGetCriticalChecks, useGetUpcomingUpdates } from '@/api/complianceCheck'

import { dateDashNotationToDot } from '@/utils/convertDate'

import { Rights } from '@/api/types/right'
import { PersonId } from '@/api/types/person'

export default defineComponent({
  name: 'Compliance',
  setup: (_, { root }) => {
    const {
      exec: getUpcomingUpdates,
      data: upcomingUpdates,
      isLoading: isLoadingUpcomingUpdates,
    } = useGetUpcomingUpdates()

    const {
      exec: getCriticalChecks,
      data: criticalChecks,
      isLoading: isLoadingCriticalChecks,
    } = useGetCriticalChecks()

    function init() {
      getUpcomingUpdates()
      getCriticalChecks()
    }
    init()

    function onClickToPerson(personId: PersonId): void {
      root.$router.push({
        name: 'person-profile',
        params: { id: String(personId) },
      })
    }

    return reactive({
      icons: {
        mdiArrowRight,
      },
      constants: {
        Rights,
      },
      state: {
        upcomingUpdates,
        criticalChecks,

        isLoadingUpcomingUpdates,
        isLoadingCriticalChecks,
      },
      listeners: {
        onClickToPerson,
      },
      functions: {
        dateDashNotationToDot,
      },
    })
  },
})
