import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  ComplianceCheckInput,
  ComplianceCheckOutput,
  UseGetComplianceChecks,
  UseCreateComplianceCheck,
  UseUpdateComplianceCheck,
  UseDeleteComplianceCheck,
  ComplianceCheckId,
  UseGetUpcomingUpdates,
  UseGetCriticalChecks,
  UpcomingUpdate,
} from './types/complianceCheck'
import { PromiseType } from '@/utils/types/PromiseType'
import { BasicEntity } from '@/api/types/misc'

const VERSION = 'v1'
const RESOURCE = 'compliance-checks'
const UPCOMING_UPDATES = 'dashboard/upcoming-updates'
const CRITICAL_CHECKS = 'dashboard/critical-checks'

function useGetComplianceChecks(): UseGetComplianceChecks {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetComplianceChecks['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
  })
  return axios
}

function useGetUpcomingUpdates(): UseGetUpcomingUpdates {
  const axios = useAxios<UpcomingUpdate[]>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}/${UPCOMING_UPDATES}`,
  })
  return axios
}

function useGetCriticalChecks(): UseGetCriticalChecks {
  const axios = useAxios<BasicEntity[]>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}/${CRITICAL_CHECKS}`,
  })
  return axios
}

function useCreateComplianceCheck(): UseCreateComplianceCheck {
  const axios = useAxios<PromiseType<ReturnType<UseCreateComplianceCheck['createComplianceCheck']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createComplianceCheck(data: ComplianceCheckInput): Promise<ComplianceCheckOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createComplianceCheck,
  }
}

function useUpdateComplianceCheck(): UseUpdateComplianceCheck {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateComplianceCheck['updateComplianceCheck']>>>({ method: 'PUT' })

  function updateComplianceCheck(id: ComplianceCheckId, data: ComplianceCheckOutput): Promise<ComplianceCheckOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateComplianceCheck,
  }
}

function useDeleteComplianceCheck(): UseDeleteComplianceCheck {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteComplianceCheck['deleteComplianceCheck']>>>({
    method: 'DELETE',
  })

  function deleteComplianceCheck(id: ComplianceCheckId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteComplianceCheck,
  }
}

export {
  useGetComplianceChecks,
  useCreateComplianceCheck,
  useUpdateComplianceCheck,
  useDeleteComplianceCheck,
  useGetUpcomingUpdates,
  useGetCriticalChecks,
}
