
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { mdiPlus, mdiPencil, mdiDelete } from '@mdi/js'

import { useGetNews, useDeleteNews } from '@/api/news'

import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { handleError } from '@/utils/handleError'

import { Rights } from '@/api/types/right'
import { NewsOutput } from '@/api/types/news'

export default defineComponent({
  name: 'News',
  components: {
    AddEditNewsDialog: () => import('@/views/dashboard/components/AddEditNewsDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
  },
  setup: () => {
    const { exec: getNews, data: news, isLoading: isLoadingNews } = useGetNews()

    function init() {
      getNews({ params: { size: 9999 } })
    }
    init()

    const activeNews = ref<NewsOutput | null>(null)

    const isAddEditModalOpen = ref(false)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onClickEdit(news: NewsOutput): void {
      isAddEditModalOpen.value = true

      activeNews.value = { ...news }
    }

    const isDeleteDialogOpen = ref(false)

    function onClickDelete(news: NewsOutput): void {
      activeNews.value = { ...news }

      isDeleteDialogOpen.value = true
    }

    const { deleteNews } = useDeleteNews()

    async function onDelete(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteNews(activeNews.value!.id)
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteDialogOpen.value = false

      activeNews.value = null

      init()
    }

    function onCloseAddEditDeleteInfoDialog(): void {
      isAddEditModalOpen.value = false

      isDeleteDialogOpen.value = false

      activeNews.value = null
    }

    return reactive({
      icons: {
        mdiPlus,
        mdiPencil,
        mdiDelete,
      },
      constants: {
        Rights,
      },
      state: {
        news,

        isLoadingNews,

        isAddEditModalOpen,
        activeNews,
        isDeleteDialogOpen,
      },
      listeners: {
        onClickEdit,

        onClickDelete,
        onDelete,

        onCloseAddEditDeleteInfoDialog,
      },
      functions: {
        hasSufficientRights,

        init,
      },
    })
  },
})
