var render = function () {
  var _vm$state$dashboardFa, _vm$state$dashboardFa2, _vm$state$dashboardFa3, _vm$state$dashboardFa4, _vm$state$dashboardFa5, _vm$state$dashboardFa6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-5 rounded-lg elevation-0",
    attrs: {
      "loading": _vm.state.isLoadingDashboard,
      "height": "42vh"
    }
  }, [_c('v-card-title', {
    staticClass: "pl-4 pt-4 font-weight-bold text-subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.factsAndFigures.countEmployees.title')) + " ")]), _c('v-card-text', {
    staticClass: "card--text"
  }, [_c('v-row', {
    staticClass: "pl-4 pb-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('span', {
    staticClass: "font-weight-bold text-subtitle-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.factsAndFigures.countMangedEmployeesTotal')) + " ")]), _c('br'), _c('span', {
    staticClass: "text-h4"
  }, [_vm._v(" " + _vm._s((_vm$state$dashboardFa = _vm.state.dashboardFactsAndFigures) === null || _vm$state$dashboardFa === void 0 ? void 0 : _vm$state$dashboardFa.countMangedEmployeesTotal) + " ")])]), _c('v-col', [_c('span', {
    staticClass: "font-weight-bold text-subtitle-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.factsAndFigures.countMangedEmployeesInternal')) + " ")]), _c('br'), _c('span', {
    staticClass: "text-h4"
  }, [_vm._v(" " + _vm._s((_vm$state$dashboardFa2 = _vm.state.dashboardFactsAndFigures) === null || _vm$state$dashboardFa2 === void 0 ? void 0 : _vm$state$dashboardFa2.countMangedEmployeesInternal) + " ")])]), _c('v-col', [_c('span', {
    staticClass: "font-weight-bold text-subtitle-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.factsAndFigures.countMangedEmployeesANUE')) + " ")]), _c('br'), _c('span', {
    staticClass: "text-h4"
  }, [_vm._v(" " + _vm._s((_vm$state$dashboardFa3 = _vm.state.dashboardFactsAndFigures) === null || _vm$state$dashboardFa3 === void 0 ? void 0 : _vm$state$dashboardFa3.countMangedEmployeesANUE) + " ")])]), _c('v-col', [_c('span', {
    staticClass: "font-weight-bold text-subtitle-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.factsAndFigures.countMangedEmployeesSystel')) + " ")]), _c('br'), _c('span', {
    staticClass: "text-h4"
  }, [_vm._v(" " + _vm._s((_vm$state$dashboardFa4 = _vm.state.dashboardFactsAndFigures) === null || _vm$state$dashboardFa4 === void 0 ? void 0 : _vm$state$dashboardFa4.countMangedEmployeesSystel) + " ")])])], 1)], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "pl-4 pt-4 font-weight-bold text-subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.factsAndFigures.openDemand.title')) + " ")]), _c('v-card-text', {
    staticClass: "card--text"
  }, [_c('v-row', {
    staticClass: "pl-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('span', {
    staticClass: "font-weight-bold text-subtitle-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.factsAndFigures.openDemandAbsolute')) + " ")]), _c('br'), _c('span', {
    staticClass: "text-h4"
  }, [_vm._v(" " + _vm._s((_vm$state$dashboardFa5 = _vm.state.dashboardFactsAndFigures) === null || _vm$state$dashboardFa5 === void 0 ? void 0 : _vm$state$dashboardFa5.openDemandAbsolute) + " ")])]), _c('v-col', [_c('span', {
    staticClass: "font-weight-bold text-subtitle-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.factsAndFigures.openDemandVZP')) + " ")]), _c('br'), _c('span', {
    staticClass: "text-h4"
  }, [_vm._v(" " + _vm._s((_vm$state$dashboardFa6 = _vm.state.dashboardFactsAndFigures) === null || _vm$state$dashboardFa6 === void 0 ? void 0 : _vm$state$dashboardFa6.openDemandVZP) + " ")])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }