
import { computed, defineComponent, reactive } from '@vue/composition-api'
import { mdiArrowRight } from '@mdi/js'

import { dateDashNotationToDot } from '@/utils/convertDate'

import { useGetContractRenewals } from '@/api/contract'

import { Rights } from '@/api/types/right'
import { PersonId } from '@/api/types/person'
import { useGetPersonsDashboardLeasing } from '@/api/person'
import { CONTRACTUAL_RELATIONSHIP_LIST } from '@/views/types'

export default defineComponent({
  name: 'PersonalDates',
  setup: (_, { root }) => {
    const {
      exec: getContractRenewals,
      data: contractRenewals,
      isLoading: isLoadingContractRenewals,
    } = useGetContractRenewals()

    const {
      exec: getPersonDashboardLeasingsScope36,
      data: personDashboardLeasingsScope36,
      isLoading: isLoadingPersonDashboardLeasingsScope36,
    } = useGetPersonsDashboardLeasing()

    const {
      exec: getPersonDashboardLeasingsScope54,
      data: personDashboardLeasingsScope54,
      isLoading: isLoadingPersonDashboardLeasingsScope54,
    } = useGetPersonsDashboardLeasing()

    function init() {
      const today = new Date()
      const todayFormatted = formatDate(today)

      const dateInOneHundredEightyDays = new Date()
      dateInOneHundredEightyDays.setDate(today.getDate() + 180)
      const dateInOneHundredEightyDaysFormatted = formatDate(dateInOneHundredEightyDays)

      getContractRenewals({ params: { numberOfRenewals: 20 } })
      getPersonDashboardLeasingsScope36({
        params: {
          contractualRelationships: CONTRACTUAL_RELATIONSHIP_LIST.ANÜ,
          anuePeriod36EndDateFrom: todayFormatted,
          anuePeriod36EndDateTo: dateInOneHundredEightyDaysFormatted,
          size: 9999,
        },
      })
      getPersonDashboardLeasingsScope54({
        params: {
          contractualRelationships: CONTRACTUAL_RELATIONSHIP_LIST.ANÜ,
          anuePeriod54EndDateFrom: todayFormatted,
          anuePeriod54EndDateTo: dateInOneHundredEightyDaysFormatted,
          size: 9999,
        },
      })
    }
    init()

    function formatDate(date: Date) {
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    function onClickToPerson(personId: PersonId): void {
      root.$router.push({
        name: 'person-profile',
        params: { id: String(personId) },
      })
    }

    const isLoading = computed(
      () =>
        isLoadingContractRenewals.value ||
        isLoadingPersonDashboardLeasingsScope36.value ||
        isLoadingPersonDashboardLeasingsScope54.value
    )

    return reactive({
      icons: {
        mdiArrowRight,
      },
      constants: {
        Rights,
      },
      state: {
        contractRenewals,
        personDashboardLeasingsScope36,
        personDashboardLeasingsScope54,

        isLoading,
      },
      listeners: {
        onClickToPerson,
      },
      functions: {
        dateDashNotationToDot,
      },
    })
  },
})
