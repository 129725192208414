import { useAxios } from '@/composables/useAxios'

import {
  NewsId,
  NewsInput,
  NewsOutput,
  UseCreateNews,
  UseDeleteNews,
  UseGetNews,
  UseGetNewsBasic,
  UseUpdateNews,
} from './types/news'

import { PromiseType } from '@/utils/types/PromiseType'
import { BasicEntity } from '@/api/types/misc'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

const VERSION = 'v1'
const RESOURCE = 'news'

function useGetNews(): UseGetNews {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetNews['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetNewsBasic(): UseGetNewsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateNews(): UseCreateNews {
  const axios = useAxios<PromiseType<ReturnType<UseCreateNews['createNews']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createNews(data: NewsInput): Promise<NewsOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createNews,
  }
}

function useUpdateNews(): UseUpdateNews {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateNews['updateNews']>>>({ method: 'PUT' })

  function updateNews(id: NewsId, data: NewsOutput): Promise<NewsOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateNews,
  }
}

function useDeleteNews(): UseDeleteNews {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteNews['deleteNews']>>>({ method: 'DELETE' })

  function deleteNews(id: NewsId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteNews,
  }
}

export { useGetNews, useGetNewsBasic, useCreateNews, useUpdateNews, useDeleteNews }
