
import { defineComponent, reactive } from '@vue/composition-api'
import { mdiArrowRight } from '@mdi/js'

import { dateDashNotationToDot } from '@/utils/convertDate'

import { useGetPersonsDashboardEntries, useGetPersonsDashboardRetirements } from '@/api/person'

import { PersonId } from '@/api/types/person'

export default defineComponent({
  name: 'Boarding',
  setup: (_, { root }) => {
    const { exec: getEntries, data: entries, isLoading: isLoadingEntries } = useGetPersonsDashboardEntries()
    const {
      exec: getRetirements,
      data: retirements,
      isLoading: isLoadingRetirements,
    } = useGetPersonsDashboardRetirements()

    function init() {
      getEntries()
      getRetirements()
    }
    init()

    function onClickToBoarding(personId: PersonId): void {
      root.$router.push({
        name: 'person-profile',
        params: { id: String(personId) },
      })
    }

    return reactive({
      icons: {
        mdiArrowRight,
      },
      state: {
        entries,
        retirements,

        isLoadingEntries,
        isLoadingRetirements,
      },
      listeners: {
        onClickToBoarding,
      },
      functions: {
        dateDashNotationToDot,
      },
    })
  },
})
