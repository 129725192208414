import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  TaskInput,
  TaskOutput,
  TaskId,
  UseCreateTask,
  UseGetTasks,
  UseGetTasksBasic,
  UseGetTask,
  UseUpdateTask,
  UseDeleteTask,
  TaskUpdateInput,
} from '@/api/types/task'
import { BasicEntity } from '@/api/types/misc'
import { PromiseType } from '@/utils/types/PromiseType'

const VERSION = 'v1'
const RESOURCE = 'tasks'

function useGetTasks(): UseGetTasks {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetTasks['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetTasksBasic(): UseGetTasksBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useGetTask(): UseGetTask {
  const axios = useAxios<PromiseType<ReturnType<UseGetTask['getTask']>>>({ method: 'GET' })

  function getTask(id: TaskId): Promise<TaskOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getTask,
  }
}

function useCreateTask(): UseCreateTask {
  const axios = useAxios<PromiseType<ReturnType<UseCreateTask['createTask']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createTask(data: TaskInput): Promise<TaskOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createTask,
  }
}

function useUpdateTask(): UseUpdateTask {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateTask['updateTask']>>>({ method: 'PUT' })

  function updateTask(id: TaskId, data: TaskUpdateInput): Promise<TaskOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateTask,
  }
}

function useDeleteTask(): UseDeleteTask {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteTask['deleteTask']>>>({ method: 'DELETE' })

  function deleteTask(id: TaskId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteTask,
  }
}

export { useGetTasks, useGetTask, useGetTasksBasic, useCreateTask, useUpdateTask, useDeleteTask }
