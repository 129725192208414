import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  ContractOutput,
  ContractId,
  ContractInput,
  UseGetContracts,
  UseGetContract,
  UseGetContractsBasic,
  UseCreateContract,
  UseUpdateContract,
  UseDeleteContract,
  UseExportContracts,
  UseGetContractRenewals,
  ContractRenewal,
} from './types/contract'
import { OptionalFiltersParam } from '@/composables/types/useFilter'
import { PromiseType } from '@/utils/types/PromiseType'
import { BasicEntity } from '@/api/types/misc'

const VERSION = 'v1'
const RESOURCE = 'contracts'
const RENEWALS = 'dashboard/renewals'

function useGetContracts(): UseGetContracts {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetContracts['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetContractRenewals(): UseGetContractRenewals {
  const axios = useAxios<ContractRenewal[]>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}/${RENEWALS}`,
  })
  return axios
}

function useGetContract(): UseGetContract {
  const axios = useAxios<PromiseType<ReturnType<UseGetContract['getContract']>>>({ method: 'GET' })

  function getContract(id: ContractId): Promise<ContractOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getContract,
  }
}

function useGetContractsBasic(): UseGetContractsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateContract(): UseCreateContract {
  const axios = useAxios<PromiseType<ReturnType<UseCreateContract['createContract']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createContract(data: ContractInput): Promise<ContractOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createContract,
  }
}

function useUpdateContract(): UseUpdateContract {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateContract['updateContract']>>>({ method: 'PUT' })

  function updateContract(id: ContractId, data: ContractOutput): Promise<ContractOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateContract,
  }
}

function useDeleteContract(): UseDeleteContract {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteContract['deleteContract']>>>({ method: 'DELETE' })

  function deleteContract(id: ContractId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteContract,
  }
}

function useExportContracts(): UseExportContracts {
  const axios = useAxios<void>({ method: 'GET' })

  async function exportContracts(filters?: OptionalFiltersParam): Promise<void> {
    await axios.exec({ url: `/${VERSION}/${RESOURCE}/export`, params: filters })
  }

  return {
    ...axios,
    exportContracts,
  }
}

export {
  useGetContracts,
  useGetContract,
  useGetContractsBasic,
  useCreateContract,
  useUpdateContract,
  useDeleteContract,
  useExportContracts,
  useGetContractRenewals,
}
