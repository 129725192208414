var render = function () {
  var _vm$state$criticalChe, _vm$state$criticalChe2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-5 rounded-lg elevation-0",
    attrs: {
      "loading": _vm.state.isLoadingUpcomingUpdates && _vm.state.isLoadingCriticalChecks,
      "height": "42vh"
    }
  }, [_c('v-card-title', {
    staticClass: "pl-4 pt-4 font-weight-bold text-subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.compliance.upcomingUpdates')) + " ")]), _c('v-card-text', {
    staticClass: "card--text"
  }, [_vm.state.upcomingUpdates ? _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.state.upcomingUpdates, function (upcomingUpdate) {
    return _c('v-list-item', {
      key: upcomingUpdate.id
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(upcomingUpdate.date)) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(upcomingUpdate.entry.name) + " ")]), _c('v-tooltip', {
      attrs: {
        "top": "",
        "open-delay": "200"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('v-btn', _vm._g({
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.listeners.onClickToPerson(upcomingUpdate.entry.id);
              }
            }
          }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiArrowRight))])], 1)];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.compliance.navigate')) + " ")])], 1)], 1);
  }), 1) : _c('span', {
    staticClass: "ml-4 absolute text-subtitle-2"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.compliance.noUpcomingUpdates')))])], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "pl-4 py-2 font-weight-bold text-subtitle-1"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('dashboard.compliance.criticalChecksCount')))]), _c('span', {
    staticClass: "pl-2"
  }, [_vm._v(" " + _vm._s((_vm$state$criticalChe = (_vm$state$criticalChe2 = _vm.state.criticalChecks) === null || _vm$state$criticalChe2 === void 0 ? void 0 : _vm$state$criticalChe2.length) !== null && _vm$state$criticalChe !== void 0 ? _vm$state$criticalChe : 0) + " ")])]), _c('v-card-title', {
    staticClass: "pl-4 py-0 font-weight-bold text-subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.compliance.criticalChecks')) + " ")]), _c('v-card-text', {
    staticClass: "card--text"
  }, [_vm.state.criticalChecks ? _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.state.criticalChecks, function (criticalCheck) {
    return _c('v-list-item', {
      key: criticalCheck.id
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', [_vm._v(" " + _vm._s(criticalCheck.name) + " ")]), _c('v-tooltip', {
      attrs: {
        "top": "",
        "open-delay": "200"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on;
          return [_c('v-btn', _vm._g({
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.listeners.onClickToPerson(criticalCheck.id);
              }
            }
          }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiArrowRight))])], 1)];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.compliance.navigate')) + " ")])], 1)], 1);
  }), 1) : _c('span', {
    staticClass: "ml-4 absolute text-subtitle-2"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.compliance.noCriticalChecks')))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }