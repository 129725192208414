
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { mdiPencil, mdiOpenInApp } from '@mdi/js'

import { useNotify } from '@/store'

import { useGetTasks, useUpdateTask } from '@/api/task'

import { dateDashNotationToDot } from '@/utils/convertDate'
import { handleError } from '@/utils/handleError'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { User } from '@/api/types/user'
import { TaskOutput } from '@/api/types/task'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'Tasks',
  components: {
    AddEditTaskDialog: () => import('@/components/common/AddEditTaskDialog.vue'),
  },
  setup: (_, { root }) => {
    const { addNotification } = useNotify()

    const currentUser = computed((): User | null => root.$store.state.auth.currentUser)

    const { exec: getOwnTasks, data: ownTasks, isLoading: isLoadingOwnTasks } = useGetTasks()
    const { exec: getTeamTasks, data: teamTasks, isLoading: isLoadingTeamTasks } = useGetTasks()

    function init() {
      getOwnTasks({ params: { assignedToUserIds: currentUser.value?.id, notSolved: true, size: 9999 } })
      getTeamTasks({
        params: { notOfType: 'onboarding,offboarding,Onboarding,Offboarding', notAssigned: true, size: 9999 },
      })
    }
    init()

    const { updateTask: updateTask } = useUpdateTask()

    async function onToggleTaskSolved(task: TaskOutput, isChecked: boolean): Promise<void> {
      if (!currentUser.value) return

      if (isChecked) {
        task.assignedTo = { id: Number(currentUser.value.id), name: String(currentUser.value.username) }
      }

      Object.entries(task).forEach(([key, value]) => value?.id && (task[key] = value.id))

      try {
        await updateTask(task.id, {
          ...task,
          solvedOn: isChecked ? new Date().toISOString().split('T')[0] : null,
          solvedBy: isChecked ? currentUser.value.id : null,
        })

        addNotification({
          text: root.$t('administration.tasks.update.success') as string,
          type: 'success',
          timeout: 3000,
        })

        init()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const isAddEditTaskDialogOpen = ref(false)

    const activeTask = ref<TaskOutput | null>({} as TaskOutput)

    function onEditTask(task: TaskOutput): void {
      activeTask.value = { ...task }

      isAddEditTaskDialogOpen.value = true
    }

    function onCloseAddEditDialog(): void {
      activeTask.value = {} as TaskOutput

      isAddEditTaskDialogOpen.value = false
    }

    return reactive({
      icons: {
        mdiPencil,
        mdiOpenInApp,
      },
      constants: {
        Rights,
      },
      state: {
        ownTasks,
        teamTasks,

        activeTask,

        isLoadingOwnTasks,
        isLoadingTeamTasks,

        isAddEditTaskDialogOpen,
      },
      listeners: {
        onToggleTaskSolved,

        onEditTask,

        onCloseAddEditDialog,
      },
      functions: {
        dateDashNotationToDot,

        init,

        hasSufficientRights,
      },
    })
  },
})
