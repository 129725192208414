
import { defineComponent, reactive } from '@vue/composition-api'

import Boarding from '@/views/dashboard/components/DashboardBoarding.vue'
import News from '@/views/dashboard/components/DashboardNews.vue'
import Compliance from '@/views/dashboard/components/DashboardCompliance.vue'
import FactsAndFigures from '@/views/dashboard/components/DashboardFactsAndFigures.vue'
import Tasks from '@/views/dashboard/components/DashboardTasks.vue'
import PersonalDates from '@/views/dashboard/components/DashboardPersonalDates.vue'

import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useAuthGetters } from '@/store'

import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'Dashboard',
  components: {
    Tasks,
    Boarding,
    Compliance,
    News,
    FactsAndFigures,
    PersonalDates,
  },
  setup(_, { root }) {
    const { isAuthenticated } = useAuthGetters()

    if (!isAuthenticated.value) root.$router.push('/login')

    return reactive({
      constants: {
        Rights,
      },
      functions: {
        hasSufficientRights,
      },
    })
  },
})
