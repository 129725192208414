var render = function () {
  var _vm$state$entries, _vm$state$retirements;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-5 rounded-lg elevation-0",
    attrs: {
      "loading": _vm.state.isLoadingEntries || _vm.state.isLoadingRetirements,
      "height": "42vh"
    }
  }, [_c('v-card-title', {
    staticClass: "pl-4 pt-4 font-weight-bold text-subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.boarding.entries')) + " ")]), _c('v-card-text', {
    staticClass: "card--text"
  }, [(_vm$state$entries = _vm.state.entries) !== null && _vm$state$entries !== void 0 && _vm$state$entries.length ? _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.state.entries, function (newPerson) {
    return _c('v-list-item', {
      key: newPerson.id
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(newPerson.date)) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(newPerson.entry.name) + " ")]), _c('v-tooltip', {
      attrs: {
        "top": "",
        "open-delay": "200"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('v-btn', _vm._g({
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.listeners.onClickToBoarding(newPerson.entry.id);
              }
            }
          }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiArrowRight))])], 1)];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.boarding.navigate')) + " ")])], 1)], 1);
  }), 1) : _c('span', {
    staticClass: "ml-4 text-subtitle-2 absolute"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.boarding.noEntries')))])], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "pl-4 pt-4 font-weight-bold text-subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.boarding.retirements')) + " ")]), _c('v-card-text', {
    staticClass: "card--text"
  }, [(_vm$state$retirements = _vm.state.retirements) !== null && _vm$state$retirements !== void 0 && _vm$state$retirements.length ? _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.state.retirements, function (retirement) {
    return _c('v-list-item', {
      key: retirement.id
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(retirement.date)) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(retirement.entry.name) + " ")]), _c('v-tooltip', {
      attrs: {
        "top": "",
        "open-delay": "200"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on;
          return [_c('v-btn', _vm._g({
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.listeners.onClickToBoarding(retirement.entry.id);
              }
            }
          }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiArrowRight))])], 1)];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.boarding.navigate')) + " ")])], 1)], 1);
  }), 1) : _c('span', {
    staticClass: "ml-4 absolute text-subtitle-2"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.boarding.noRetirements')))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }