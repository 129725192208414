var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-5 rounded-lg elevation-0",
    attrs: {
      "loading": _vm.state.isLoadingNews,
      "height": "42vh"
    }
  }, [_c('v-card-title', {
    staticClass: "pl-4 pt-4 font-weight-bold text-subtitle-1 d-flex justify-space-between"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('dashboard.news.title')) + " ")]), _vm.functions.hasSufficientRights(_vm.constants.Rights.NEWS_CREATE) ? _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.state.isAddEditModalOpen = true;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiPlus))])], 1) : _vm._e()], 1), _c('v-card-text', [_vm.state.news.length ? _c('v-list', {
    attrs: {
      "dense": "",
      "two-line": ""
    }
  }, [_c('div', _vm._l(_vm.state.news, function (newsMessage) {
    return _c('v-list-item', {
      key: newsMessage.id
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(newsMessage.title) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(newsMessage.content) + " ")])], 1), _c('v-list-item-icon', [_vm.functions.hasSufficientRights(_vm.constants.Rights.NEWS_UPDATE) ? _c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.listeners.onClickEdit(newsMessage);
        }
      }
    }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiPencil))])], 1) : _vm._e(), _vm.functions.hasSufficientRights(_vm.constants.Rights.NEWS_DELETE) ? _c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.listeners.onClickDelete(newsMessage);
        }
      }
    }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiDelete))])], 1) : _vm._e()], 1)], 1);
  }), 1)]) : _c('span', {
    staticClass: "ml-4 text-subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.news.noNews')) + " ")])], 1), _vm.state.isAddEditModalOpen ? _c('AddEditNewsDialog', {
    attrs: {
      "news-to-edit": _vm.state.activeNews
    },
    on: {
      "reload-news": function reloadNews($event) {
        return _vm.functions.init();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditModalOpen", $$v);
      },
      expression: "state.isAddEditModalOpen"
    }
  }) : _vm._e(), _vm.state.isDeleteDialogOpen ? _c('CommonDeleteDialog', {
    on: {
      "delete": function _delete($event) {
        return _vm.listeners.onDelete();
      },
      "cancel": function cancel($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      }
    },
    model: {
      value: _vm.state.isDeleteDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteDialogOpen", $$v);
      },
      expression: "state.isDeleteDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.news.delete.text')) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }