const CONTRACTUAL_RELATIONSHIP = [
  { name: 'ANÜ' },
  { name: 'extern' },
  { name: 'intern' },
  { name: 'DBS' },
  { name: 'Nachwuchs' },
  { name: 'Sonstige' },
]

enum CONTRACTUAL_RELATIONSHIP_LIST {
  ANÜ = 'ANÜ',
  extern = 'extern',
  intern = 'intern',
  DBS = 'DBS',
  Sonstige = 'Sonstige',
}

export { CONTRACTUAL_RELATIONSHIP, CONTRACTUAL_RELATIONSHIP_LIST }
