var render = function () {
  var _vm$state$contractRen, _vm$state$personDashb, _vm$state$personDashb2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-5 rounded-lg elevation-0",
    attrs: {
      "loading": _vm.state.isLoading,
      "height": "42vh"
    }
  }, [_c('v-card-title', {
    staticClass: "pl-4 pt-4 font-weight-bold text-subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.contractRenewals.title')) + " ")]), _c('v-card-text', {
    staticClass: "card--text"
  }, [(_vm$state$contractRen = _vm.state.contractRenewals) !== null && _vm$state$contractRen !== void 0 && _vm$state$contractRen.length ? _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.state.contractRenewals, function (renewal) {
    return _c('v-list-item', {
      key: renewal.id
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(renewal.date)) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(renewal.entry.name) + " ")]), _c('v-tooltip', {
      attrs: {
        "top": "",
        "open-delay": "200"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('v-btn', _vm._g({
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.listeners.onClickToPerson(renewal.entry.id);
              }
            }
          }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiArrowRight))])], 1)];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.contractRenewals.navigate')) + " ")])], 1)], 1);
  }), 1) : _c('span', {
    staticClass: "ml-4 absolute text-subtitle-2"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.contractRenewals.noRenewals')))])], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "pl-4 pt-4 font-weight-bold text-subtitle-1"
  }, [_c('v-row', [_c('v-col', [_vm._v(" " + _vm._s(_vm.$t('dashboard.leasing36.title')) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(_vm.$t('dashboard.leasing54.title')) + " ")])], 1)], 1), _c('v-card-text', {
    staticClass: "card--text"
  }, [_c('v-row', [_c('v-col', [(_vm$state$personDashb = _vm.state.personDashboardLeasingsScope36) !== null && _vm$state$personDashb !== void 0 && _vm$state$personDashb.length ? _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.state.personDashboardLeasingsScope36, function (leasingScope36) {
    return _c('v-list-item', {
      key: leasingScope36.id
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(leasingScope36.anuePeriod36EndDate)) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(leasingScope36.person.name) + " ")]), _c('v-tooltip', {
      attrs: {
        "top": "",
        "open-delay": "200"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on;
          return [_c('v-btn', _vm._g({
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.listeners.onClickToPerson(leasingScope36.person.id);
              }
            }
          }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiArrowRight))])], 1)];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.contractRenewals.navigate')) + " ")])], 1)], 1);
  }), 1) : _c('span', {
    staticClass: "ml-4 absolute text-subtitle-2"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.leasing36.noPersons')))])], 1), _c('v-divider', {
    staticClass: "py-16",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', [(_vm$state$personDashb2 = _vm.state.personDashboardLeasingsScope54) !== null && _vm$state$personDashb2 !== void 0 && _vm$state$personDashb2.length ? _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.state.personDashboardLeasingsScope54, function (leasingScope54) {
    return _c('v-list-item', {
      key: leasingScope54.id
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(leasingScope54.anuePeriod54EndDate)) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(leasingScope54.person.name) + " ")]), _c('v-tooltip', {
      attrs: {
        "top": "",
        "open-delay": "200"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref3) {
          var on = _ref3.on;
          return [_c('v-btn', _vm._g({
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.listeners.onClickToPerson(leasingScope54.person.id);
              }
            }
          }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiArrowRight))])], 1)];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.contractRenewals.navigate')) + " ")])], 1)], 1);
  }), 1) : _c('span', {
    staticClass: "ml-4 absolute text-subtitle-2"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.leasing54.noPersons')))])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }