var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.functions.hasSufficientRights(_vm.constants.Rights.DASHBOARD) ? _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', [_vm.functions.hasSufficientRights(_vm.constants.Rights.TASK_READ_ALL) || _vm.functions.hasSufficientRights(_vm.constants.Rights.TASK_READ_OWN) ? _c('Tasks') : _vm._e(), _vm.functions.hasSufficientRights(_vm.constants.Rights.PERSON_READ_ALL) ? _c('Boarding') : _vm._e()], 1), _c('v-col', [_c('FactsAndFigures'), _vm.functions.hasSufficientRights(_vm.constants.Rights.NEWS_READ) ? _c('News') : _vm._e()], 1), _c('v-col', [_vm.functions.hasSufficientRights(_vm.constants.Rights.PERSON_READ_ALL) && _vm.functions.hasSufficientRights(_vm.constants.Rights.CONTRACT_READ_ALL) ? _c('PersonalDates') : _vm._e(), _vm.functions.hasSufficientRights(_vm.constants.Rights.PERSON_READ_ALL) && _vm.functions.hasSufficientRights(_vm.constants.Rights.COMPLIANCE_CHECK_READ_ALL) ? _c('Compliance') : _vm._e()], 1)], 1)], 1) : _c('div', {
    staticClass: "img-container"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }