var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-5 rounded-lg elevation-0",
    attrs: {
      "loading": _vm.state.isLoadingTeamTasks && _vm.state.isLoadingOwnTasks,
      "height": "42vh"
    }
  }, [_c('v-card-title', {
    staticClass: "pl-4 pt-4 font-weight-bold text-subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.tasks.ownTasks')) + " ")]), _c('v-card-text', {
    staticClass: "card--text"
  }, [_vm.state.ownTasks.length ? _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.state.ownTasks, function (ownTask) {
    return _c('v-list-item', {
      key: ownTask.id
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.TASK_UPDATE) ? _c('v-col', {
      attrs: {
        "align-self": "center",
        "cols": "2"
      }
    }, [_c('v-checkbox', {
      staticClass: "mt-0 pt-0",
      attrs: {
        "input-value": Boolean(ownTask.solvedOn),
        "hide-details": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
        },
        "change": function change($event) {
          return _vm.listeners.onToggleTaskSolved(ownTask, $event);
        }
      }
    })], 1) : _vm._e(), _c('v-col', {
      attrs: {
        "align-self": "center",
        "cols": "4"
      }
    }, [_vm._v(" " + _vm._s(ownTask.title) + " ")]), ownTask.dueTo ? _c('v-col', {
      attrs: {
        "align-self": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(ownTask.dueTo)) + " ")]) : _c('v-col', {
      attrs: {
        "align-self": "center"
      }
    }, [_vm._v(" - ")]), _vm.functions.hasSufficientRights(_vm.constants.Rights.TASK_UPDATE) ? _c('v-col', {
      staticClass: "text-right",
      attrs: {
        "align-self": "center"
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.listeners.onEditTask(ownTask);
        }
      }
    }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiPencil))])], 1)], 1) : _vm._e()], 1)], 1);
  }), 1) : _c('span', {
    staticClass: "ml-4 absolute text-subtitle-2"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.tasks.noTasks')))])], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "pl-4 pt-4 font-weight-bold text-subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.tasks.teamTasks')) + " ")]), _c('v-card-text', {
    staticClass: "card--text"
  }, [_vm.state.teamTasks.length && _vm.functions.hasSufficientRights(_vm.constants.Rights.TASK_READ_ALL) ? _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.state.teamTasks, function (teamTask) {
    return _c('v-list-item', {
      key: teamTask.id
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.TASK_UPDATE) ? _c('v-col', {
      attrs: {
        "align-self": "center",
        "cols": "2"
      }
    }, [_c('v-checkbox', {
      staticClass: "mt-0 pt-0",
      attrs: {
        "input-value": Boolean(teamTask.solvedOn),
        "hide-details": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
        },
        "change": function change($event) {
          return _vm.listeners.onToggleTaskSolved(teamTask, $event);
        }
      }
    })], 1) : _vm._e(), _c('v-col', {
      attrs: {
        "align-self": "center",
        "cols": "4"
      }
    }, [_vm._v(" " + _vm._s(teamTask.title) + " ")]), teamTask.dueTo ? _c('v-col', {
      attrs: {
        "align-self": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(teamTask.dueTo)) + " ")]) : _c('v-col', {
      attrs: {
        "align-self": "center"
      }
    }, [_vm._v(" - ")]), _vm.functions.hasSufficientRights(_vm.constants.Rights.TASK_UPDATE) ? _c('v-col', {
      staticClass: "text-right",
      attrs: {
        "align-self": "center"
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.listeners.onEditTask(teamTask);
        }
      }
    }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiPencil))])], 1)], 1) : _vm._e()], 1)], 1);
  }), 1) : _c('span', {
    staticClass: "ml-4 absolute text-subtitle-2"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.tasks.noTasks')))])], 1), _vm.state.isAddEditTaskDialogOpen ? _c('AddEditTaskDialog', {
    attrs: {
      "is-edit-mode": true,
      "task-to-edit": _vm.state.activeTask
    },
    on: {
      "reload-tasks": function reloadTasks($event) {
        return _vm.functions.init();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditTaskDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditTaskDialogOpen", $$v);
      },
      expression: "state.isAddEditTaskDialogOpen"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }